import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { FaStamp } from 'react-icons/fa'
import * as styles from '../styles/apostille.module.css'

export default function Apostille() {
    return (
        <Layout>
            <section className={styles.apostille}>
                <div className="u-center-text u-margin-bottom-big">
                    <h2 className="heading-secondary">
                        What is an Apostille?
                    </h2>
                </div>
                <div className="row">
                    <div className="col-1-of-2">
                        <p className="paragraph">
                            There is a plethora of information over the internet, which go more or less in depth.
                        </p>
                        <p className="paragraph">
                            To make things simpler, we will stick to: it is a <AnchorLink to="/apostille#stamp">stamp</AnchorLink> issued by the FCO which confirms the stamps, signature, seal on the original document is true. It is issued to be used in a country which is member of Hague Convention.
                        </p>
                    </div>
                    <div className="col-1-of-2">
                        <p className="paragraph">
                            Once a document has got the Apostille Certificate affixed you can then present it in any country which recognises the Apostille (i.e who has signed the Hague Agreement).
                        </p>
                        <p className="paragraph">
                            This means that there will be no extra struggle for you as the authority receiving the document will automatically accept it and will not request further proof or evidence.
                        </p>
                    </div>
                </div>
                <div className="seperator">

                </div>
                <div className="row">
                    <div className="col-1-of-2">
                        <h3 className="heading-tertiary u-margin-bottom-medium">
                            All Apostille Certificates look the same and contain the same information:
                        </h3>
                        <p className="paragraph">
                            1. Country of issue
                        </p>
                        <p className="paragraph">
                            2. Who has signed the document <br/>
                        </p>
                        <p className="paragraph">
                            3. The capacity in which the person signed the document
                        </p>
                        <p className="paragraph">
                            4. Details of any seal on the document 
                        </p>
                        <p className="paragraph">
                            5. Place of issue 
                        </p>
                        <p className="paragraph">
                            6. Date of issue
                        </p>
                        <p className="paragraph">
                            7. Issuing authority
                        </p>
                        <p className="paragraph">
                            8. Apostille Certificate number
                        </p>
                        <p className="paragraph">
                            9. Stamp of issuing authority
                        </p>
                        <p className="paragraph">
                            10. Signature of representative of issuing authority.
                        </p>                 
                    </div>
                    <div className="col-1-of-2">
                        <div className={styles.image_container}>
                            <StaticImage
                            src="../images/model.png"
                            alt="Apostilles for all education certificates"
                            placeholder="blurred"
                            layout="fullWidth"
                            />
                        </div>
                    </div>
                </div>
                <div className="seperator"></div>
                <div className="u-center-text u-margin-bottom-big">
                    <h2 className="heading-secondary">
                        What services can we offer you?
                    </h2>
                </div>
                <div className="row">
                    <div className="col-1-of-2">
                        <p className="paragraph">
                            We can offer you both an Apostille service and translation services. If you need to have legalised a translation, please note that only the translations that have been done by Bostico International will be processed.
                        </p>
                    </div>
                    <div className="col-1-of-2">
                        <p className="paragraph">
                            Beginning 1 April 2011 the FCO closed their public counters and  the public will no longer be able to hand deliver documents. We are here to ensure your documents are still processed securely and in a time.
                        </p>
                    </div>
                </div>
                <div className="u-center-text u-margin-bottom-big">
                    <h2 className="heading-secondary">
                        Which countries will accept an Apostille?
                    </h2>
                </div>
                <div className="row">
                    <div className="col-1-of-2">
                        <p className="paragraph">
                            Apostille Certificates issued by the FCO are guaranteed to be accepted in the following <Link to="/countries">countries</Link> without further legalisation. Some countries which are not listed, such as Angola for example, may still accept the Apostille. <Link to="/contact">Contact us</Link> for further information.
                        </p>
                    </div>
                    <div className="col-1-of-2">
                        <p className="paragraph">
                            Every country has got its own rules and regulations and the Apostille Certificate is issued by the state from which the document originates. There are cases where another state can issue the Apostille. If you need need an Apostille to a document which has been issued by another state, contact our experts and we can make it happen for you.
                        </p>
                    </div>
                </div>
                <div className="seperator"></div>
                <div className="u-center-text u-margin-bottom-big">
                    <h2 className="heading-secondary">
                        What documents can we legalise?
                    </h2>
                </div>
                <div className="row">
                    <div className="col-1-of-2">
                        <p className="paragraph">
                            We can legalise/apply an Apostille on almost all genuine documents issued in the UK.
                        </p>
                        <p className="paragraph">
                            <Link to="/documents">Non-exhaustive list.</Link>
                        </p>
                    </div>
                    <div className="col-1-of-2">
                    </div>            
                </div>
                <div className="u-center-text u-margin-bottom-big">
                    <h2 className="heading-secondary">
                        How much does it cost?
                    </h2>
                </div>
                <div className="row">
                    <div className="col-1-of-2">
                        <p className="paragraph">
                            We have a transparent and easy to use price structure. We do not charge on tiers and we do not make difference between private individuals orders and corporate orders. They are treated with the same attention to detail and charged the same.
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            £65 for the first document and £ 55 thereafter.
                        </h3>
                        <h3 className="heading-tertiary">
                            Prices do not include VAT.
                        </h3>
                    </div>
                    <div className="col-1-of-2">
                        <h3 className="heading-tertiary u-margin-bottom-medium">
                            Timeframe:
                        </h3>
                        <p className="paragraph">
                            You need to allow 5 working days for us return your documents. Our standard delivery is <a href="https://www.postoffice.co.uk/mail/uk-signed" target="_blank" rel="noreferrer">First Class Signed For</a> and this is included in the price we charged you. If however, you need the document quicker, let us know what is your preferred delivery method and we shall prepare a quote for you.
                        </p>
                    </div>
                </div>
                <div className="seperator"></div>
                    <h3 className="heading-tertiary u-margin-bottom-medium u-center-text">
                            We can translate to and from the languages of all countries who accept an Apostille. Sample of a Romanian translation:
                    </h3>
                <div className="row">
                    <div className="col-1-of-2">
                        <p className="paragraph">
                            APOSTILA <br/>
                            (Convenţia de la Haga, 5 octombrie 1961)
                        </p>
                        <p className="paragraph text-bold">
                            REGATUL UNIT AL MARII BRITANII SI AL IRLANDEI DE NORD            
                        </p>
                        <p className="paragraph">
                            1. Tara: Regatul Unit al Marii Britanii si al Irlandei de Nord Acest document 
                        </p>
                        <p className="paragraph">
                            2. a fost semnat de: C A Andersen 
                        </p>
                        <p className="paragraph">
                            3. in capacitate de : Notar Public Hampshire
                        </p>
                        <p className="paragraph">
                            4. Cu sigiliu/ştampila: Notarului Public Mentionat 
                        </p>
                        <p className="paragraph">
                            5. La Londra
                        </p>
                        <p className="paragraph">
                            6. Certificat la data de 28 Februarie 2012
                        </p>
                        <p className="paragraph">
                            7. de către Prim-secretarul de Stat si al Commonwealth al Majestăţii Sale 
                        </p>
                        <p className="paragraph">
                            8. sub numărul: J158057 
                        </p>
                        <p className="paragraph">
                            9. Ştampila 
                        </p>
                        <p className="paragraph">
                            10. Semnătura (R. Brooks)  [semnătura indescifrabila]
                        </p>                      
                    </div>
                    <div className="col-1-of-2">
                        <p className="paragraph u-margin-bottom-small">
                        Daca acest document se foloseşte intr-o tara care nu a semnat Tratatul de la Haga la data se 5 Octombrie 1961, trebuie prezentat la Secţia consulara a misiunii ce reprezintă acea tara. Apostila sau certificatul legalizat confirma numai ca semnătura, ştampila sau sigiliul de pe document sunt adevărate. Aceasta nu semnifica ca conţinutul acestui document este corect sau ca biroul Foreign and Commonwealth aproba conţinutul acesteia.
                        </p>
                    </div>
                </div>
                <div className="seperator"></div>
                <div className="u-center-text u-margin-bottom-big">
                    <h2 className="heading-secondary">
                        Looking for an apostille stamp?
                    </h2>
                </div>
                <div className="row" id="stamp">
                    <div className="col-1-of-2">
                        <p className="paragraph">
                            An Apostille certificate and an Apostille stamp is one and the same thing. No matter how you call it, it does the same thing.
                        </p>
                        <p className="paragraph">
                            If the country belongs to the 1961 Hague Convention Abolishing the Requirement of Legalization for Foreign Public Documents, the apostille is used. The Convention provides for the simplified certification of notarized documents by use of a special form called an "apostille" in certifying the document.
                        </p>
                        <p className="paragraph">
                            The apostille does not need to be ribboned onto the document being certified. With the certification by the Hague Convention apostille, the document is entitled to recognition in the country of intended use, and no additional authentication or legalization is required.
                        </p>
                    </div>
                    <div className="col-1-of-2">
                        <div className={styles.stamp}>
                            <FaStamp />
                        </div>
                    </div>
                </div>       
            </section>
        </Layout>
    )
}
